import React from "react";
import { navigate } from "gatsby-link";
import Layout from "../../components/Layout";
import { FormattedMessage } from "gatsby-plugin-intl";

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error));
  };

  render() {
    return (
      <Layout>
        <div className="container heading-container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="section">
                <div className="container heading-container">
                  <h1
                    className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
                    style={{
                      lineHeight: "1",
                      padding: "0.25em"
                    }}
                  >
                    <FormattedMessage id="contact_us" />
                  </h1>
                </div>
                <div className="contact-section">
                  <span className="contacts-title">
                    <FormattedMessage id="tel_nr" />
                  </span>
                  <span>
                    <a className="contacts" href="tel:+37065042050">
                      +370 650 42050
                    </a>
                  </span>
                </div>
                <div className="contact-section" style={{marginBottom: `20px`}}>
                  <span className="contacts-title"></span>
                  <span>
                    <a className="contacts" href="tel:+37061013163">
                      +370 610 13163
                    </a>
                  </span>
                </div>
                <div className="contact-section">
                  <span className="contacts-title">
                    <FormattedMessage id="email" />
                  </span>
                  <span>
                    <a className="contacts" href="mailto:info@voiceovers.lt">
                      info@voiceovers.lt
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
